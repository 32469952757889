<template>
	<v-container fluid tag="section">
		<v-form ref="form" v-model="valid" lazy-validation autocomplete="new-password">
			<ti-card :icon="$icons[type]">
				<template #title><span>{{ isEdit ? 'Edit' : 'New' }}</span> {{ pageTitle }}</template>
				<template #title-actions-shown>
					<v-btn elevation="2" fab small class="mr-4" @click="preview" title="Preview">
						<v-icon>mdi-eye</v-icon>
					</v-btn>
					<v-btn color="success" elevation="2" fab small class="mr-4" @click="save">
						<v-icon>{{ $icons.save }}</v-icon>
					</v-btn>
				</template>
				<template #title-actions-hidden>
					<ti-confirm @confirm="destroy" message="Are you sure you want to delete this resource? All users progress will also be deleted." button-text="Delete">
						<v-btn color="error" elevation="2" fab small class="mr-4">
							<v-icon>{{ $icons.delete }}</v-icon>
						</v-btn>
					</ti-confirm>
				</template>

				<ti-form-layout>
					<template #sidebar>

						<v-col cols="12">
							<v-select v-model="value.status" :items="resourceStatuses" item-text="label" item-value="value" label="Status"></v-select>
						</v-col>

						<v-col cols="12">
							<ti-date-picker v-model="value.published_date" label="Published date"></ti-date-picker>
						</v-col>

						<v-col cols="12">
							<ti-date-picker v-model="value.updated_date" label="Updated date"></ti-date-picker>
							<v-btn color="primary" @click="recentlyUpdated">RECENTLY UPDATED</v-btn>
						</v-col>

						<v-col cols="12" v-if="value.type === 'Course'">
							<v-select v-model="value.brand_id" :items="brands" item-text="name" item-value="id" label="Brand"></v-select>
						</v-col>

						<v-col cols="12">
							<v-switch v-model="value.premium" label="Premium"></v-switch>
						</v-col>

						<v-col cols="12" v-if="value.premium">
							<ti-date-picker v-model="value.premium_until" label="Premium until"></ti-date-picker>
						</v-col>

						<v-col cols="12" v-if="value.type === 'Course'">
							<v-select v-model="value.xapi_content_id" :items="xapi_contents" :rules="validations.xapi_content" :item-text="(item) => (`${item.name} (${item.brand_name})`)" item-value="id" label="Xapi Content"></v-select>
						</v-col>

						<v-col cols="12">
							<ti-image-upload v-model="value.featured_image_id" label="Featured image" :image-id="value.featured_image_id" :key="value.id"></ti-image-upload>
						</v-col>

						<v-col cols="12">
							<v-checkbox v-model="value.show_on_website" label="Show on website" persistent-hint hint="Un-ticking this box will mean this resource will not show on the website. Only un-tick this if you wish to hide this resource from the search etc"></v-checkbox>
						</v-col>

					</template>

					<template>
						<v-col cols="12">
							<v-text-field v-model="value.title" :rules="validations.title" label="Title"></v-text-field>
						</v-col>

						<v-col cols="12">
							<v-text-field v-model="value.subtitle" :rules="validations.subtitle" label="Subtitle"></v-text-field>
						</v-col>

						<v-col cols="12">
							<v-select
								v-model="value.topics_attributes"
								:items="topics_for_resources"
								label="Topics"
								multiple
								item-text="name"
								item-value="id"
								return-object
								chips
								persistent-hint
								:rules="validations.topics"
							></v-select>
						</v-col>

						<v-col cols="12" class="mt-5">
							<ti-expertise-slider v-model="value.expertise"></ti-expertise-slider>
						</v-col>

						<v-col cols="12" class="mt-5">
							<v-combobox v-model="resource_tags" label="Tag this resource as another type" :items="resourceTagOptions" :return-object="false" multiple chips deletable-chips></v-combobox>
						</v-col>

						<v-col cols="12" class="mt-5" v-if="value.type === 'Intermission'">
							<v-text-field v-model="value.mp4_link" label="MP4 Link"></v-text-field>
						</v-col>

					</template>
				</ti-form-layout>
			</ti-card>

			<!-- SEO -->
			<ti-card :icon="$icons.seo">
				<template #title>SEO</template>
				<template>
					<ti-form-layout>
						<template>
							<v-col cols="12" lg="6">
								<v-text-field v-model="value.meta_title" label="Meta title" :rules="validations.meta_title"></v-text-field>
							</v-col>
							<v-col cols="12" lg="6">
								<ti-pill-combo v-model="value.meta_keywords" :key="value.id" label="Meta Keywords"></ti-pill-combo>
							</v-col>
							<v-col cols="12" lg="6">
								<v-text-field v-model="value.meta_description" label="Meta description" :rules="validations.meta_description" counter="150"></v-text-field>
							</v-col>

							<v-col cols="12" lg="6">
								<v-text-field v-model="value.slug" :rules="validations.slug" label="Slug"></v-text-field>
							</v-col>

							<v-col cols="12">
								<ti-google-preview :url="value.slug" :meta-title="value.meta_title" :meta-description="value.meta_description" url-prefix="resources"></ti-google-preview>
							</v-col>
						</template>
					</ti-form-layout>
				</template>
			</ti-card>

			<!-- RELATED -->
			<ti-card :icon="$icons.connect">
				<template #title>Related resources</template>
				<template>
					<template v-for="(related_resource, index) in value.related_resources_attributes">
						<v-row v-if="!related_resource._destroy">
							<v-col>
								<v-select
									v-model="related_resource.related_resource_id"
									:items="related_resources"
									item-value="id"
									:item-text="(item) => `${item.title} - ${item.type}`"
									label="Related resource"
								></v-select>
							</v-col>
						</v-row>
					</template>

					<v-row>
						<v-col>
							<v-btn color="success" elevation="2" fab small class="mr-4" @click="addRelatedResource">
								<v-icon>{{ $icons.new }}</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</template>
			</ti-card>

			<!-- DOCUMENTS -->
			<ti-card :icon="$icons.document">
				<template #title>Documents</template>
				<ti-form-layout hide-title>
					<template>
						<v-col cols="12" v-for="(document, index) in value.documents_attributes">
							<v-row v-if="!document._destroy">
								<v-col>
									<ti-document-upload label="Document" v-model="value.documents_attributes[index]"></ti-document-upload>
								</v-col>
								<v-col cols="auto">
									<v-btn color="error" elevation="2" fab small class="mr-4" @click="deleteDocument">
										<v-icon>{{ $icons.delete }}</v-icon>
									</v-btn>
								</v-col>
							</v-row>
						</v-col>

						<v-col>
							<v-btn color="primary" elevation="2" fab small class="mr-4" @click="addDocument">
								<v-icon>{{ $icons.plus }}</v-icon>
							</v-btn>
						</v-col>
					</template>
				</ti-form-layout>
			</ti-card>

			<!-- CONTENT -->
			<ti-card :icon="$icons.content">
				<template #title>Content</template>
				<template>
					<v-row>
						<v-col cols="12" lg="6">
							<v-text-field v-model="value.caption" :rules="validations.caption" label="Caption"></v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" lg="6">
							<v-textarea v-model="value.summary" label="Summary"></v-textarea>
						</v-col>
						<v-col cols="12">
							<ti-editor v-model="value.resource_content_attributes.content" no-title></ti-editor>
						</v-col>
					</v-row>
					<v-row v-if="value.type === 'Podcast'">
						<v-col cols="12">
							<v-text-field v-model="value.audio_link" label="Podcast audio file link"></v-text-field>
						</v-col>
						<v-col cols="12" v-if="value.audio_link">
							<p>This player is just to test the link is working correctly. A different player will be displayed on the site.</p>
							<audio :src="value.audio_link" controls></audio>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12" lg="4">
							<v-text-field v-model="value.minutes" type="number" label="Minutes to read / listen"></v-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<v-text-field  v-model="value.audio_transcript_url" label="Audio transcript url"></v-text-field>
						</v-col>
						<v-col cols="12">
							<v-text-field  v-model="value.text_transcript_url" label="Text transcript url"></v-text-field>
						</v-col>
					</v-row>
				</template>
			</ti-card>

			<!-- INDEX CARD -->
			<ti-card :icon="$icons.content">
				<template #title>Index Card</template>
				<template>
					<v-row>
						<v-col cols="12">
							<v-text-field v-model="value.resource_content_attributes.index_card_title" label="Title"></v-text-field>
						</v-col>
						<v-col cols="12">
							<ti-editor v-model="value.resource_content_attributes.index_card_content" no-title></ti-editor>
						</v-col>
						<v-col cols="12">
							<v-select v-model="value.resource_content_attributes.index_card_position" :items="['Top', 'Bottom']" label="Placement"></v-select>
						</v-col>
					</v-row>
				</template>
			</ti-card>

			<!-- CTA -->
			<ti-card :icon="$icons.content">
				<template #title>CTA</template>
				<template>
					<v-row>
						<v-col cols="12">
							<v-checkbox v-model="value.resource_content_attributes.cta_enabled" label="Enable CTA"></v-checkbox>
							<v-alert type="error" outlined v-if="!value.resource_content_attributes.cta_enabled">
								<p>Remember to remove the CTA shortcode from the content.</p>
								<p class="text-h4">[%cta%]</p>
							</v-alert>
							<v-alert v-else type="success" outlined>
								<p>Remember to add the CTA shortcode from the content.</p>
								<p class="text-h4">[%cta%]</p>
							</v-alert>
						</v-col>
					</v-row>

					<template v-if="value.resource_content_attributes.cta_enabled">
						<v-row>
							<v-col cols="12">
								<v-textarea v-model="value.resource_content_attributes.cta_text" label="Text"></v-textarea>
							</v-col>
							<v-col cols="12" md="4">
								<ti-color-picker v-model="value.resource_content_attributes.cta_bg_color" label="Background color"></ti-color-picker>
							</v-col>
							<v-col cols="12" md="4">
								<ti-color-picker v-model="value.resource_content_attributes.cta_shape_color_1" label="Shape color 1"></ti-color-picker>
							</v-col>
							<v-col cols="12" md="4">
								<ti-color-picker v-model="value.resource_content_attributes.cta_shape_color_2" label="Shape color 2"></ti-color-picker>
							</v-col>
						</v-row>

						<v-row>
							<v-col cols="12">
								Example (not an exact match but close enough for a preview):
							</v-col>
							<v-col cols="12" class="pa-0">
								<div class="w-100" :style="`background-color: ${cta_bg_color}`">
									<ti-shape variant="circle" location="top-left" size="225" :color="cta_shape_color_1"></ti-shape>
									<ti-shape variant="square" location="bottom-right" size="225" :color="cta_shape_color_2"></ti-shape>
									<div style="width: 75%;" class="px-16 py-12">
										<p :style="`color: ${getTextColorForBackground(cta_bg_color)}`" class="text-h3" style="white-space: pre-wrap;">{{ value.resource_content_attributes.cta_text }}</p>
										<v-btn rounded color="black" class="white--text text-h4" style="text-transform: none">Free for 30 days</v-btn>
									</div>
								</div>
							</v-col>
						</v-row>
					</template>
				</template>
			</ti-card>

			<ti-card :icon="$icons.redirect">
				<template #title>Redirects</template>
				<template>
					<div v-for="(redirect, index) in value.url_redirects_attributes">
						<v-row v-if="!redirect._destroy">
							<v-col>
								<v-text-field v-model="redirect.redirect_from" :rules="validations.redirect" label="From"></v-text-field>
							</v-col>
							<v-col>
								<v-text-field v-model="redirect.redirect_to" :rules="validations.redirect" label="To"></v-text-field>
							</v-col>
							<v-col class="shrink">
								<v-btn color="error" elevation="2" fab small class="mr-4" @click="deleteRedirect(redirect, index)">
									<v-icon>{{ $icons.delete }}</v-icon>
								</v-btn>
							</v-col>
						</v-row>
					</div>

					<v-row>
						<v-col>
							<v-btn color="success" elevation="2" fab small class="mr-4" @click="addRedirect">
								<v-icon>{{ $icons.new }}</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</template>
			</ti-card>
		</v-form>
	</v-container>
</template>
<script>

import validationRules from "../../util/validation";
import {sync, get} from "vuex-pathify";
import helperMixin from "../../mixins/helperMixin";
import TiDatePicker from "../../components/TiDatePicker";
import TiFormLayout from "../../components/TiFormLayout";
import TiPillCombo from "../../components/TiPillCombo";
import models from "../../models"
import TiColorPicker from "../../components/TIColorPicker";
import getTextColorForBackground from "../../util/colorContrast";
import TiShape from "../../components/TiShape.vue";

export default {
	name: "ResourcesForm",
	components: {TiShape, TiColorPicker, TiPillCombo, TiFormLayout, TiDatePicker},
	props: ['id', 'value', 'type', 'plural', 'title'],
	mixins: [helperMixin],
	computed: {
		cta_bg_color() {
			if (this.value.resource_content_attributes.cta_bg_color) {
				return this.value.resource_content_attributes.cta_bg_color
			} else {
				return '#2f5e5b'
			}
		},
		cta_shape_color_1() {
			if (this.value.resource_content_attributes.cta_shape_color_1) {
				return this.value.resource_content_attributes.cta_shape_color_1
			} else {
				return '#ade041'
			}
		},
		cta_shape_color_2() {
			if (this.value.resource_content_attributes.cta_shape_color_2) {
				return this.value.resource_content_attributes.cta_shape_color_2
			} else {
				return '#eac2ce'
			}
		},
		typeCapitalized() {
			return this.type[0].toUpperCase() + this.type.substring(1)
		},
		pluralCapitalized() {
			return this.plural[0].toUpperCase() + this.plural.substring(1)
		},
		isEdit() {
			return this.$route.meta?.edit
		},
		resource_tags: {
			get() {
				return this.value.resource_tags?.split(',')
			},
			set(value) {
				this.value.resource_tags = value.join(',')
			}
		},
		topics_for_resources: get('topics/forResources'),
		topics: sync('topics/topics'),
		brands: sync('brands/brands'),
		related_resources: sync('resources/resources'),
		xapi_contents: sync('xapi_contents/xapi_contents'),
		pageTitle() {
			if (this.title) return this.title
			return this.typeCapitalized
		},
	},
	watch: {
		resource(newValue) {
			this.$emit('input', newValue);
		},
		'value.title'(value) {
			if (!this.value.id || (this.value.id && !this.value.slug)) {
				this.value.slug = this.slugify(value)
			}
			if (!this.value.id || (this.value.id && !this.value.meta_title)) {
				this.value.meta_title = value
			}
		},
		'value.subtitle'(value) {
			if (!this.value.id || (this.value.id && !this.value.meta_description)) {
				this.value.meta_description = value
			}
		}
	},
	data: () => ({
		image: '',
		fab: false,
		subDrawer: false,
		valid: false,
		validations: {
			title: validationRules('Title', ['required', 'minLength:2']),
			xapi_content: validationRules('Xapi Content', ['required']),
			topics: validationRules('Topics', ['required', 'minLength:1']),
			meta_title: validationRules('Meta title', ['required', 'minLength:2']),
			meta_description: validationRules('Meta description', ['required', 'minLength:2']),
			caption: validationRules('Caption', ['required', 'minLength:2']),
		},
		resourceTagOptions: [
			{text: 'Article', value: 'article'},
			{text: 'Case Study', value: 'case_study'},
			{text: 'Infographic', value: 'infographic'},
			{text: 'Podcast', value: 'podcast'},
			{text: 'Video', value: 'video'},
		]
	}),
	beforeMount() {
		this.init()
	},
	methods: {
		getTextColorForBackground,
		init() {
			this.$store.set('topics/getTopics!', null)
			this.$store.set('brands/getBrands!', null)
			this.$store.set('resources/getResources!', null)
			this.$store.set('xapi_contents/getXapi_contents!', null)
		},
		save() {
			if (this.$refs.form.validate()) {
				if (this.isEdit) {
					this.$api.resources.update(this.id, this.value)
						.then(response => {
							this.$toast.add(`${this.typeCapitalized} updated successfully`, 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: `${this.pluralCapitalized}`})
						})
						.catch(error => this.$toast.handleResponseErrors(this, error)
						)
				} else {
					this.$api.resources.create(this.value)
						.then(response => {
							this.$toast.add(`${this.typeCapitalized} created successfully`, 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: `${this.pluralCapitalized}`})
						})
						.catch(error => {
							this.$toast.handleResponseErrors(this, error)
						})
				}

			} else {
				this.$toast.add('Please fix validation errors', 'error')
			}
		},
		destroy() {
			this.$api.resources.delete(this.id, this.resource)
				.then(response => {
					this.$toast.add(`${this.typeCapitalized} deleted successfully`, 'success')
					this.$route.meta.disableWarn = true;
					this.$router.push({name: `${this.pluralCapitalized}`})
				})
				.catch(error => {
					this.$toast.add(error.response.statusText, 'error')
				})
		},
		addRedirect() {
			this.value.url_redirects_attributes.push(models.UrlRedirect())
		},
		addRelatedResource() {
			this.value.related_resources_attributes.push(models.RelatedResource())
		},
		deleteRedirect(redirect, index) {
			if (redirect.id !== null) {
				this.course.url_redirects_attributes[index]['_destroy'] = true
			} else {
				this.course.url_redirects_attributes.splice(index, 1)
			}
		},
		deleteDocument() {
			return true
		},
		addDocument() {
			this.value.documents_attributes.push(models.Document())
		},
		recentlyUpdated() {
			this.value.updated_date = new Date().toISOString().slice(0, 10)
		},
		preview() {
			if (process.env.production) {
				window.open(`https://targetinternet.com/resources/${this.value.slug}`, '_blank');
			} else {
				window.open(`http://localhost:3000/resources/${this.value.slug}`, '_blank');
			}
		}
	}
}
</script>